<template>
  <div>
    <b-modal
        ref="premiumModal"
        :hide-footer="true"
        v-model="showPremiumModal"
        ok-title="Save"
        size="lg"
    >
      <EditCompanyPremium @created="onCompanyPremiumChanged" :date-from="companyPremiumFrom" :date-to="companyPremiumTo" :uid="companyPremiumUid"></EditCompanyPremium>
    </b-modal>
    <UsersFilters
      :status.sync="statusFilter"
      :type.sync="typeFilter"
      :industry.sync="industryFilter"
      :created.sync="createdFilter"
      :country.sync="countryFilter"
      :is-incomplete.sync="isIncompleteFilter"
      :is-inactive.sync="inactiveFilter"
      :company-type.sync="companyType"
      :is-premium.sync="isPremium"
      :is-not-verified.sync="IsNotVerified"
      :viewsDate.sync="viewsRange"
    >
    </UsersFilters>

    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
            <b-button @click="exportUsers" class="ml-5" variant="success">
              Export
            </b-button>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input v-model="searchQuery" class="d-inline-block mr-1" placeholder="Search..." />
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refTable"
        :items="fetchUsers"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <!-- Column: User -->
        <template #cell(user)="data">
          <div class="d-flex">
            <b-media vertical-align="center">
              <template #aside>
                <b-avatar
                  size="32"
                  :src="data.item.profileImage"
                  :text="avatarText(data.item.fullName)"
                  :variant="`light-${resolveUserRoleVariant(data.item.type)}`"
                />
              </template>
              <b-link
                target="_blank"
                class="font-weight-bold d-block text-nowrap"
                :to="resolveUserLink(data.item.type, data.item.uid)"
              >
                {{ data.item.name }}
              </b-link>
              <small class="text-muted">{{ data.item.email }}</small>
            </b-media>
            <feather-icon
              @click="loginAsUser(data.item.uid)"
              v-if="userRole === 'Superadmin' && data.item.isActive"
              icon="ExternalLinkIcon"
              size="18"
              class="ml-2 cursor-pointer"
              :class="`text-primary`"
            />
          </div>
        </template>

        <!-- Column: Role -->
        <template #cell(type)="data">
          <div class="text-nowrap">
            <feather-icon
              :icon="resolveUserRoleIcon(data.item.type)"
              size="18"
              class="mr-50"
              :class="`text-${resolveUserRoleVariant(data.item.type)}`"
            />
            <span class="align-text-top text-capitalize">{{ resolveUserRole(data.item) }}</span>
          </div>
        </template>

        <template #cell(userCreatedType)="data">
          <b-badge :variant="`light-${resolveUserStatusVariant(data.item.userCreatedType)}`" class="text-capitalize">
            {{ data.item.userCreatedType.toLowerCase() }}
          </b-badge>
        </template>

        <template #cell(fieldOfWorks)="data">
          {{ data.item.fieldOfWorks.join(' ') }}
        </template>

        <template #cell(created)="data">
          <div class="cell-width">{{ data.item.created | mapDate }}</div>
        </template>

        <template #cell(lastLogin)="data">
          <div class="cell-width">{{ data.item.lastLoginDate | mapDate }}</div>
        </template>

        <template #cell(isActive)="data">
          <feather-icon
            :icon="resolveProfileCompletedIcon(data.item.isActive)"
            size="18"
            class="mr-50"
            :class="data.item.isActive ? 'text-success' : 'text-primary'"
          />
        </template>

        <template #cell(isVisible)="data">
          <feather-icon
            :icon="resolveProfileCompletedIcon(data.item.isVisible)"
            size="18"
            class="mr-50"
            :class="data.item.isVisible ? 'text-success' : 'text-primary'"
          />
        </template>

        <template #cell(isProfileFullyFilled)="data">
          <feather-icon
            :icon="resolveProfileCompletedIcon(data.item.isProfileFullyFilled)"
            size="18"
            class="mr-50"
            :class="data.item.isProfileFullyFilled ? 'text-success' : 'text-primary'"
          />
        </template>

        <template #cell(completedPercent)="data">
          <span>{{ data.item.profileFulfilledPercentage }} %</span>
        </template>

        <template #cell(isPremium)="data">
          <feather-icon
            :icon="resolveProfileCompletedIcon(data.item.isPremium)"
            size="18"
            class="mr-50"
            :class="data.item.isPremium ? 'text-success' : 'text-primary'"
          />
        </template>

        <template #cell(isVerifiedRecruiter)="data">
          <feather-icon
            :icon="resolveProfileCompletedIcon(data.item.isVerifiedRecruiter)"
            size="18"
            class="mr-50"
            :class="data.item.isVerifiedRecruiter ? 'text-success' : 'text-primary'"
          />
        </template>

        <template #cell(subscriptionExpiresAt)="data">
          {{ data.item.subscriptionExpiresAt | mapDate }}
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL" class="options-dropdown">
            <template #button-content>
              <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
            </template>

            <b-dropdown-item v-if="data.item.type === 'Crew'" @click="toggleUserVisibility(data.item.uid)">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">
                {{ data.item.isVisible ? 'Hide' : 'Show' }}
              </span>
            </b-dropdown-item>

            <b-dropdown-item @click="blockUser(data.item.uid)">
              <feather-icon icon="AlertCircleIcon" />
              <span class="align-middle ml-50">{{ data.item.isActive ? 'Block' : 'Unblock' }}</span>
            </b-dropdown-item>

            <b-dropdown-item v-if="data.item.type === 'Recruiter' && data.item.isProfileFullyFilled" @click="openCompanyPremiumModal(data.item.uid, data.item.recruiterPremiumFrom, data.item.recruiterPremiumTo)">
              <feather-icon icon="AlertCircleIcon" />
              <span class="align-middle ml-50">Edit company premium</span>
            </b-dropdown-item>

            <b-dropdown-item
              @click="togglePremium(data.item.uid)"
              v-if="userRole === 'Superadmin' && data.item.isProfileFullyFilled && data.item.type === 'Crew'"
            >
              <feather-icon icon="UserIcon" />
              <span class="align-middle ml-50">{{
                !data.item.isPremium ? 'Activate premium' : 'Deactivate premium'
              }}</span>
            </b-dropdown-item>

            <b-dropdown-item
              @click="toggleCompanyVerified(data.item.uid)"
              v-if="userRole === 'Superadmin' && data.item.type === 'Recruiter' && data.item.isProfileFullyFilled"
            >
              <feather-icon icon="UserIcon" />
              <span class="align-middle ml-50">{{
                !data.item.isVerifiedRecruiter ? 'Activate verified' : 'Deactivate verified'
              }}</span>
            </b-dropdown-item>

            <b-dropdown-item @click="deleteProfile(data.item.uid)">
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50"> Delete</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
            <span class="text-muted"
              >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalCount"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import useUsersList from './useUsersList'
import userStoreModule from './userStoreModule'
import UsersFilters from '@/components/users/UsersFilters'
import EditCompanyPremium from '@/components/users/EditCompanyPremium'

export default {
  components: {
    EditCompanyPremium,
    UsersFilters,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,

    vSelect,
  },
  data() {
    return {
      showPremiumModal: false,
      companyPremiumUid: null,
      companyPremiumFrom: null,
      companyPremiumTo: null
    }
  },
  methods: {
    deleteProfile(uid) {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.deleteUser(uid).then(()=> {
            this.$swal({
              icon: 'success',
              title: 'Deleted!',
              text: 'User has been deleted.',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          }).catch((error)=>{
            this.$swal({
              icon: 'error',
              title: 'Error!',
              text: 'User has not been deleted.',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
            })
          })

        }
      })
    },
    async loginAsUser(uid) {
      const { token } = await this.$httpService.auth.getUserToken(uid)
      const url = process.env.VUE_APP_REDIRECT_LINK + '/proxy-auth?token=' + token

      // console.log(url)
      window.open(url, '_blank')
    },
    openCompanyPremiumModal(uid, from, to) {
      this.companyPremiumUid = uid
      this.companyPremiumFrom = from
      this.companyPremiumTo = to
      this.showPremiumModal = true
    },
    onCompanyPremiumChanged() {
      this.showPremiumModal = false
      this.refetchData()
    }
  },
  setup() {
    const userRole = localStorage.getItem('role')

    const usersList = useUsersList()

    return {
      avatarText,
      userRole,
      ...usersList,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.options-dropdown{
  position: static;
}

.cell-width{
  width: 85px;
}

</style>

<style lang="scss"></style>
